.tournaments-table td {
  transition: all ease-in-out 0.2s !important;
}

.color-1 > td {
  background-color: #ebf6e7 !important;
}

.color-2 > td {
  background-color: #fdfce5 !important;
}

.color-3 > td {
  background-color: #e7ecf6 !important;
}

.color-4 > td {
  background-color: #636363 !important;
  color: #ffffff;
}

.color-5 > td {
  background-color: #872136 !important;
  color: #ffffff;
}

.color-0:hover > td,
.color-1:hover > td,
.color-2:hover > td,
.color-3:hover > td,
.color-4:hover > td,
.color-5:hover > td {
  border-bottom-width: 2px;
  border-bottom-color: #1890ff;
}

/* Playing myself and totals table rows classes*/

.playing-myself-table td {
  transition: all ease-in-out 0.2s !important;
}

.green > td {
  background-color: #ebf6e7 !important;
}

.red > td {
  background-color: #f6e7e7 !important;
}

.blue > td {
  background-color: #e7ecf6 !important;
}

.yellow > td {
  background-color: #fdfce5 !important;
}

.white > td {
  background-color: #f6f6f6 !important;
}

.underscored > td {
  background-color: #fdfce5 !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #858585 !important;
}

.underscored:hover > td {
  border-bottom-width: 2px !important;
  border-bottom-color: #1890ff !important;
}

.green:hover > td,
.red:hover > td,
.blue:hover > td,
.yellow:hover > td,
.white:hover > td {
  border-bottom-width: 2px;
  border-bottom-color: #1890ff;
}
